import React from "react";
import Apps from "./components/Apps";
import Menu from "./components/Navbar";

const Internal = () => {

  /*For changing background image by changing the color*/
  const bgImg = { backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-3/Homev2.png)` };

  document.body.classList.remove("landing-page");
  document.body.classList.remove("home-style-two");
  document.body.classList.add("home-style");
  document.body.classList.add("three");
  return (
    <div>
      <Menu />

      <section id="home" className="home home-three vertical-scrolling" style={bgImg}>
        <div className="container">
          <div className="row">
            <div className="col-md-5 col-sm-12">
              <div className="home-contain">
                <div className="text-white">
                  <div className="contain">
                    <h1 className="text-white">
                      Interne Apps
                    </h1>
                    <p className="slide-cap-desc">
                      Verschiedene Apps für die Speicherung, Verwaltung und Analyse der Spektren.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Apps />

    </div>
  );
};

export default Internal;
