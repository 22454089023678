import React from "react";
import translator from "../translator";

const Partners = ({ lang }) => {
  const name = {
    fontWeight: 'bold',
  }
  const link = {
    textDecoration: 'none'
  }
  const bgImg = { backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-3/feature-bg1.png)` }

  const partners = {
    left: [
      {
        partner: 'Fraunhofer-Institut für Verfahrenstechnik und Verpackung IVV',
        role: 'Projektkoordination und Referenz Analytik',
        contact: 'Thorsten Tybussek',
        url: 'https://www.ivv.fraunhofer.de/',
        icon: 'fraunhofer_ivv.png',
        num_br: lang === 'de' ? 2 : 1
      },
      {
        partner: 'LiquoSystems GmbH',
        role: 'Kellereitechnik',
        contact: 'Stephan Wieland',
        url: 'https://liquosystems.de/',
        icon: 'liquoSystems.png',
        num_br: 0
      },
      {
        partner: 'QuoData Gesellschaft für Qualitätsmanagement und Statistik mbH',
        role: 'Studiendesign, Auswertung, Datenmanagement und KI',
        contact: 'Dr. Karina Hettwer',
        url: 'https://quodata.de/en',
        icon: 'qd.png',
        num_br: 0
      }
    ],
    right: [
      {
        partner: 'Dienstleistungszentrum Ländlicher Raum (DLR) – Rheinpfalz, Weincampus Neustadt',
        role: 'Spektral- und Referenz-Analytik, Önologie und Weinbau',
        contact: 'Prof. Dr. Dominik Durner',
        url: 'https://www.weincampus-neustadt.de/forschung/dominik-durner/qualitaetssicherung-im-weinbau-mittels-ki-basierte-mir',
        icon: 'weincampus.png',
        num_br: 0
      },
      {
        partner: 'IRPC Infrared - Process Control GmbH',
        role: 'Messgerätentwicklung',
        contact: 'Robert Bischof',
        url: 'http://www.infrared-processcontrol.de/',
        icon: 'irpc.png',
        num_br: 0
      }
    ]
  }

  function render_partner({ partner, role, contact, url, icon, num_br }) {
    return (
      <li>
        <a style={link} href={url} target="_blank" rel="noreferrer">
          <div className="feature-icon">
            <img src={`${process.env.PUBLIC_URL}/assets/images/partners/${icon}`} alt="icon" />
          </div>
          <div className="feature-subtitle">
            <h3>&nbsp;</h3>
          </div>
          <div>
            <p>
              <span style={name}>{t(partner)}</span><br />
              <span>{t(role)}</span><br />
              <span>{t(contact)}</span>
              {[...Array(num_br)].map(() => <br/>)}
            </p>
          </div>
        </a>
      </li>)
  }

  const t = translator(lang)
  return (
    <section id="feature" className="feature" style={bgImg}>
      <div className="feature-decor">
        <div className="feature-circle1">
          <img src={`${process.env.PUBLIC_URL}/assets/images/grapes/grapes.png`} alt="" />
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="offset-lg-4 col-lg-8">
            <div className="row">
              <div className="col-sm-12 mrgn-md-top">
                <h2 className="title">
                  {t('Projektpartner')}
                </h2>
              </div>
              <div className="col-12 col-md-6">
                <ul className="feature-style">
                  {partners['left'].map(render_partner)}
                </ul>
              </div>
              <div className="col-12 col-md-6 sm-m-top">
                <ul className="feature-style">
                  {partners['right'].map(render_partner)}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Partners;
