import React from "react";
import translator from "../translator";

const About = ({ lang }) => {

  const bgImg = {
    backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-3/about-bg.png`
  };

  const t = translator(lang)
  return (
    <section id="about" className="about" style={bgImg}>
      <div className="container">
        <div className="row ">
          <div className="col-md-7">
            <div className="about-contain">
              <div>
                <h2 className="title">
                  {t('Über')} <span>Smartgrape</span>
                </h2>
                <h3>{t('Ziele und geplante Innovationen')}</h3>
                <p className="caption-about">
                  {t('Das Projekt soll den Fortschritt der Digitalisierung im Weinbau und Landwirtschaft allgemein fördern, um den Einsatz von Künstlicher Intelligenz (KI) zu ermöglichen. Ausgehend von der Digitalisierung im Weinbau soll durch den Einsatz der KI in Kombination mit einem mobilen und robusten MIR-Messsystem die gesamte Wertschöpfungskette hinsichtlich der Rohstoffeigenschaften und Nachhaltigkeit optimiert werden.')}
                </p>
                <h3>{t('Kompetenzen')}</h3>
                <p className="caption-about">
                  {t('Das Konsortium ist mit seinen Projektpartnern aus Forschung und Wirtschaft äußerst multidisziplinär aufgestellt. Es sind Forschungseinrichtungen aus dem Bereich der verfahrenstechnischen Industrie und Weinbau sowie Industriepartner aus der Technologieentwicklung und Datenanalyse beteiligt.')}
                </p>
                <h3>{t('Erwartete Ergebnisse')}</h3>
                <p className="caption-about">
                  {t('Eine große Vielfalt an Rebsorten, Standort- und Umwelteinflüssen und zahlreiche technische Möglichkeiten im Weinbau erfordern verlässliche Maßnahmen zur Qualitätsbeurteilung von Weintrauben. Das Projekt soll die Digitalisierung in Form multivariater Traubenanalytik und KI im Weinbau fördern und über praxistaugliche Maßnahmen der Datenerhebung und -verarbeitung mehr Sicherheit und Zielorientierung in den Prozess der Weinbereitung bringen. Das zu entwickelnde Messsystem soll dabei in der Lage sein, durch seine Robustheit und Mobilität Trauben entlang der gesamten Wertschöpfungskette umfassend zu charakterisieren.')}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <div className="about-decor">
              <div className="about-circle1">
                <img src={`${process.env.PUBLIC_URL}/assets/images/grapes/grape3.png`} alt="team1" />
              </div>
              <div className="about-circle2">
                <img src={`${process.env.PUBLIC_URL}/assets/images/grapes/grape1.png`} alt="banner1" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section >
  );
};

export default About;
