import React, { useState } from "react";
import {
  Collapse,
  Nav, Navbar,
  NavbarBrand,
  NavbarToggler, NavItem, NavLink
} from "reactstrap";
import translator from "../translator";


const Menu = ({ lang, onLangChange }) => {
  const [isOpen, setIsOpen] = useState(false);


  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const HandleScroll = () => {
    if (window.scrollY >= 60) {
      document.getElementById("Navbar")?.classList.add("darkHeader");
      document.getElementById("site-logo").src = `${process.env.PUBLIC_URL}/assets/images/logo_green.png`

    } else {
      document.getElementById("Navbar")?.classList.remove("darkHeader");
      document.getElementById("site-logo").src = `${process.env.PUBLIC_URL}/assets/images/logo_white.png`
    }
  };

  const logged_in = sessionStorage.getItem('token')

  window.addEventListener("scroll", HandleScroll);
  const auth_link = logged_in ?
    <NavItem><NavLink
      className="nav-link"
      activeClass="active"
      href={`${process.env.PUBLIC_URL}/sign-out`}
      spy={true}
      smooth={true}
      offset={-70}
      duration={500}
      onClick={toggle}
    >Log out
    </NavLink></NavItem> : <NavItem><NavLink
      className="nav-link"
      activeClass="active"
      href={`${process.env.PUBLIC_URL}/sign-in`}
      spy={true}
      smooth={true}
      offset={-70}
      duration={500}
      onClick={toggle}
    >Log in
    </NavLink>
    </NavItem>

  const t = translator(lang)
  return (
    <Navbar
      id="Navbar"
      className="navbar navbar-expand-lg navbar-light theme-nav fixed-top"
    >
      <div id="navbar-main" className="container">
        <NavbarBrand
          className="navbar-brand"
          href={`${process.env.PUBLIC_URL}/`}
        >
          <img id="site-logo" src={`${process.env.PUBLIC_URL}/assets/images/logo_white.png`} alt="logo" />
        </NavbarBrand>
        <NavbarToggler className="navbar-toggler" onClick={toggle} />
        <Collapse
          id="navbarSupportedContent"
          className="default-nav"
          isOpen={isOpen}
          navbar
        >
          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLink
                className="nav-link"
                activeClass="active"
                href={`${process.env.PUBLIC_URL}/#top`}
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onClick={toggle}
              >
                Home
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className="nav-link"
                activeClass="active"
                href={`${process.env.PUBLIC_URL}/#about`}
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onClick={toggle}
              >
                {t('About')}
              </NavLink>
            </NavItem>
            {logged_in &&
              <NavItem>
                <NavLink
                  className="nav-link"
                  activeClass="active"
                  href={`${process.env.PUBLIC_URL}/internal`}
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  onClick={toggle}
                >Apps
                </NavLink>
              </NavItem>}
            {auth_link}
            <NavItem>
              {/* eslint-disable-next-line */}
              <a href="" offset="-70" duration="500" className="nav-link nav-link" onClick={(ev) => { ev.preventDefault(); onLangChange(lang === 'de' ? 'en' : 'de') }}>
                {lang === 'de' ? 'EN' : 'DE'}
              </a>
            </NavItem>
          </Nav>
        </Collapse>
      </div>
    </Navbar>
  );
};

export default Menu;
