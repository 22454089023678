const translations = {
    'KI-basiertes MIR-Messsystem zur Qualitätsbestimmung im Weinbau':
        'AI-based MIR measuring system for determining quality in viticulture',
    'Ziele und geplante Innovationen': 'Aim and planned innovations',
    'Über': 'About',
    'Das Projekt soll den Fortschritt der Digitalisierung im Weinbau und Landwirtschaft allgemein fördern, um den Einsatz von Künstlicher Intelligenz (KI) zu ermöglichen. Ausgehend von der Digitalisierung im Weinbau soll durch den Einsatz der KI in Kombination mit einem mobilen und robusten MIR-Messsystem die gesamte Wertschöpfungskette hinsichtlich der Rohstoffeigenschaften und Nachhaltigkeit optimiert werden.':
        'The project is intended to promote the progress of digitization in viticulture and agriculture in general in order to enable the use of artificial intelligence (AI). Based on digitization in viticulture, the entire value chain is to be optimized in terms of raw material properties and sustainability through the use of AI in combination with a mobile and robust MIR measuring system.',
    'Kompetenzen': 'competencies ',
    'Das Konsortium ist mit seinen Projektpartnern aus Forschung und Wirtschaft äußerst multidisziplinär aufgestellt. Es sind Forschungseinrichtungen aus dem Bereich der verfahrenstechnischen Industrie und Weinbau sowie Industriepartner aus der Technologieentwicklung und Datenanalyse beteiligt.':
        'With its project partners from research and industry, the consortium is extremely multidisciplinary. Research institutions from the field of process engineering and viticulture as well as industrial partners from technology development and data analysis are involved.',
    'Erwartete Ergebnisse': 'Expected results',
    'Eine große Vielfalt an Rebsorten, Standort- und Umwelteinflüssen und zahlreiche technische Möglichkeiten im Weinbau erfordern verlässliche Maßnahmen zur Qualitätsbeurteilung von Weintrauben. Das Projekt soll die Digitalisierung in Form multivariater Traubenanalytik und KI im Weinbau fördern und über praxistaugliche Maßnahmen der Datenerhebung und -verarbeitung mehr Sicherheit und Zielorientierung in den Prozess der Weinbereitung bringen. Das zu entwickelnde Messsystem soll dabei in der Lage sein, durch seine Robustheit und Mobilität Trauben entlang der gesamten Wertschöpfungskette umfassend zu charakterisieren.':
        'A large variety of grape varieties, location and environmental influences and numerous technical possibilities in viticulture require reliable measures to assess the quality of grapes. The project is intended to promote digitization in the form of multivariate grape analysis and AI in viticulture and bring more security and goal orientation to the winemaking process through practical data collection and processing measures. The measuring system to be developed should be able to comprehensively characterize grapes along the entire value chain due to its robustness and mobility.',
    'Projektpartner': 'project partners',
    'Fraunhofer-Institut für Verfahrenstechnik und Verpackung IVV': 'Fraunhofer Institute for Process Engineering and Packaging IVV',
    'Projektkoordination und Referenz Analytik': 'Project coordination and reference analytical measurements',
    'Kellereitechnik': 'Engineering and technology in wineries',
    'QuoData Gesellschaft für Qualitätsmanagement und Statistik mbH': 'QuoData - Quality & Statistics! GmbH',
    'Studiendesign, Auswertung, Datenmanagement und KI': 'Study design, evaluation, data management and AI',
    'Spektral- und Referenz-Analytik, Önologie und Weinbau': 'Spectral and reference analytical measurement, oenology and viticulture',
    'Messgerätentwicklung': 'Development of MIR measurement instruments',
    'Erfahren Sie mehr über': 'Get to know more about',
    'Ansprechpartner': 'Contact',
    'About': 'About',
}

function clean(text) {
    return text.replace('\n', '').replace(/\s\s+/g, ' ')
}

export default function translator(lang) {
    return (text) => lang === 'en' ? (translations[clean(text)] || text) : text
}
